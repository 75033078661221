import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

import visitor from "./modules/visitor";
import sesion from "./modules/sesion";
import record from "./modules/record";
import user from "./modules/user";
import zone from "./modules/zone";

export default new Vuex.Store({
  state: {
    alert: {},
    business: {
      name: "SEGURIDAD MOSGAL S.A.S",
      nit: "NIT 900.199.958",
    },
    loading: false,
  },
  getters: {
    companyName_(state) {
      return state.business;
    },
    stateLoading_(state) {
      return state.loading;
    },
    getSendAlert_(state) {
      return state.alert;
    },
  },
  mutations: {
    sendAlert_(state, [code, type, descrip, option]) {
      state.alert = {
        state: true,
        type: type,
        code: code,
        description: descrip,
        option: option,
      };
    },
    deleteAlert_(state) {
      state.alert = "";
    },
    loadingState_(state) {
      state.loading = !state.loading;
    },
  },
  modules: {
    visitor,
    record,
    sesion,
    zone,
    user,
  },
});
