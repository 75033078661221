<template>
  <v-app :style="{ background: '#eceef9' }">
    <spinner />
    <router-view />
    <ALERT
      @cancelAlert="cancelAlert()"
      @confirm="confirm()"
      @exitEsc="cancel()"
      @cancel="cancel()"
      v-if="alert.state"
      :alert="alert"
    ></ALERT>
  </v-app>
</template>

<script>
import spinner from "../src/components/Spinner.vue";
import { mapGetters, mapMutations } from "vuex";
import { Alert } from "@/mixins/alert";
export default {
  name: "App",
  mixins: [Alert],
  components: {
    spinner,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      getSendAlert_: "getSendAlert_",
    }),
  },
  watch: {
    getSendAlert_() {
      if (this.getSendAlert_ != "") {
        this.sendAlert(this.getSendAlert_.code, this.getSendAlert_.type, this.getSendAlert_.descrip, this.getSendAlert_.option);
      }
    },
  },
  methods: {
    ...mapMutations({ deleteAlert_: "deleteAlert_" }),
    async cancel() {
      await this.deletAlert();
      this.deleteAlert_();
    },
  },
};
</script>
<style lang="scss">
@import "./assets/app/style";
</style>
