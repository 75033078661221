<template>
  <v-dialog v-model="alert.state" persistent width="500">
    <v-card width="500">
      <v-container>
        <v-alert border="top" colored-border :color="color" class="my-0 py-0">
          <v-row align="center">
            <v-col cols="8" lg="9" md="9" sm="9" class="ml-0 pl-0">
              <v-row justify="center" align="center">
                <v-col cols="12" lg="12" md="12" sm="12" class="ml-0 pl-0">
                  <h2 :class="`mt-2 ${color}--text text-center`">
                    {{ title }}
                  </h2>
                  <h4 class="text-center">
                    {{ body }}
                  </h4>
                </v-col>
                <v-col cols="12" lg="12" md="12" sm="12" v-if="!btn_cancel" class="mx-auto py-0 my-0">
                  <v-card-actions class="mx-0">
                    <v-btn ref="btn3" @click="cancel" class="botone mx-auto" :color="color" outlined
                      >cerrar
                      <v-icon :color="color" dark translate="true" class="ml-2">fa-circle-xmark</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-col>
                <v-col cols="6" lg="6" md="6" sm="6" class="py-2 my-0" v-if="btn_cancel">
                  <v-btn ref="btn1" outlined color="success" width="110" class="botone ml-md-13 ml-sm-10" @click="confirm">
                    Aceptar
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" lg="6" md="6" sm="6" class="py-2 my-0" v-if="btn_cancel">
                  <v-btn color="error" outlined width="110" @click="cancel" class="botone" ref="btn2">
                    cancelar
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" lg="3" md="3" sm="3">
              <lottie-animation
                :animationData="type_alert"
                class="mx-auto botone mt-3"
                style="height: 100px"
                v-if="alert.state"
                :autoPlay="true"
                ref="anim_login"
                id="anim_login"
                :loop="true"
                :speed="2"
                content
              />
            </v-col>
          </v-row>
        </v-alert>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import error from "../../src/assets/image/error.json";
import info from "../../src/assets/image/info.json";
import warning from "../../src/assets/image/warning.json";
import success from "../../src/assets/image/success.json";
export default {
  props: {
    alert: Object,
  },
  components: {
    LottieAnimation,
  },
  data() {
    return {
      list_alert: {
        warning: ["mdi-alert-circle", "¡Precaución!", warning],
        error: ["mdi-close-octagon-outline", "¡Error!", error],
        success: ["mdi-check-circle-outline", "¡Realizado!", success],
        info: ["mdi-information-outline", "¡Información!", info],
      },
      btn_cancel: false,
      type_alert: "",
      title: "",
      color: "",
      icon: "",
      body: "",
    };
  },
  methods: {
    msjalert(code) {
      this.btn_cancel = true;
      setTimeout(() => {
        this.$refs.btn2.$el.focus();
      }, 100);
      switch (code) {
        //Impressions.
        case "xlsx":
          this.body = `¿Generar Excel Formato XLSX?`;
          break;
        case "pdf":
          this.body = `¿Generar Impresión Formato PDF?`;
          break;
        case "csv":
          this.body = `¿Generar Impresión Formato CSV?`;
          break;
        //Users.
        case "user":
          this.body = "¿Desea crear usuario?";
          break;
        case "DL-U":
          this.body = "¿Desea eliminar usuario?";
          break;
        //Zones.
        case "DL-Z":
          this.body = "¿Desea eliminar punto de control?";
          break;
        case "DL-P":
          this.body = `Desea eliminar el punto "${this.alert.description}"`;
          break;
        //Session.
        case "OUT":
          this.body = "¿Desea cerrar la sesión?";
          break;
        default:
          this.body = this.alert.code;
          break;
      }
      return this.body;
    },
    msjError(code) {
      setTimeout(() => {
        this.$refs.btn3.$el.focus();
      }, 100);
      switch (code) {
        // Field.
        case "FIELD-0":
          this.body = "Este campo es requerido";
          break;
        case "FIELD-1":
          this.body = "Todos campos son requeridos";
          break;
        // Users.
        case "USER-0":
          this.body = "Debes ingresar un usuario";
          break;
        case "USER-1":
          this.body = "Nombre de usuario en uso";
          break;
        case "USER-2":
          this.body = "Datos incorrectos, verifique los campos";
          break;
        case "USER-3":
          this.body = "No hiciste ningún cambio";
          break;
        case "USER-POST":
          this.body = "Usuario creado correctamente";
          break;
        case "USER-PUT":
          this.body = "Usuario modificado";
          break;
        case "USER-ERROR":
          this.body = "Error creando usuario";
          break;
        case "USER-INAC":
          this.body = "Usuario inactivo, imposible acceder";
          break;
        case "PASS-0":
          this.body = `La contraseña es igual a la anterior`;
          break;
        case "PASS-1":
          this.body = `Contraseña cambiada correctamente`;
          break;
        case "PASS-2":
          this.body = `La contraseña es incorrecta`;
          break;
        case "PASS-3":
          this.body = `La contraseña no fue cambiada, intentelo nuevamente`;
          break;
        // Adress.
        case "ADRESS-1":
          this.body = `La dirección o nombre ya existe`;
          break;
        // Zone.
        case "Z-1":
          this.body = `Puesto de control creado`;
          break;
        case "Z-2":
          this.body = `No se pudo crear el punto de control`;
          break;
        case "Z-3":
          this.body = `No se pudo eliminar el punto de control`;
          break;
        case "Z-4":
          this.body = `Puesto de control eliminado`;
          break;
        case "Z-P":
          this.body = `Este puesto de control no tiene puntos agregados`;
          break;
        // Identification document
        case "ID-1":
          this.body = `Número de cédula existe`;
          break;
        // Location
        case "LC":
          this.body = `Ubicación no enviada redireccionando`;
          break;
        case "LC-0":
          this.body = `La ubicación es ${this.alert.description}`;
          break;
        case "LC-1":
          this.body = `Acceso a la ubicación denegada`;
          break;
        case "LC-2":
          this.body = `La ubicación no está disponible`;
          break;
        case "LC-3":
          this.body = `Lo sentimos, el navegador no admite la geolocalización`;
          break;
        case "LC-4":
          this.body = `Este codigo QR no esta siendo escaneado en la ubicación correcta `;
          break;
        case "LC-5":
          this.body = `No estas enviando la ubicación al escanear el código QR`;
          break;
        case "LC-6":
          this.body = `PARE: ${this.alert.description}`;
          break;
        case "LC-7":
          this.body = `Este código QR no tiene ubicación registrada`;
          break;
        case "LC-8":
          this.body = `Ya se calibro la ubicación de este codigo QR`;
          break;
        case "LC-QR":
          this.body = `La ubicación del codigo QR ha sido establecida correctamente ${this.alert.description}`;
          break;
        case "LC-Z":
          this.body = `Registro de ubicación no tiene zona de control establecida`;
          break;
        case "LC-P":
          this.body = `Registro de ubicación no existe el punto QR`;
          break;
        case "LPN":
          this.body = `Este punto QR no esta establecido con ubicación`;
          break;
        // Server
        case "SERV-0":
          this.body = `No se pudo establecer conexión con el servidor, verifica la conexión`;
          break;
        // Point Qr
        case "P-0":
          this.body = `El punto ya existe`;
          break;
        case "P-1":
          this.body = `Punto creado exitosamente`;
          break;
        case "P-2":
          this.body = `Punto eliminado correctamente`;
          break;
        case "P-3":
          this.body = `Error eliminando punto`;
          break;
        case "P-E":
          this.body = `Error creando punto`;
          break;
        // Qr
        case "QR-01":
          this.body = `No existen registros`;
          break;
        case "QR-0":
          this.body = `Registro agregado correctamente `;
          break;
        case "QR-1":
          this.body = `El código QR no es válido para hacer el registro`;
          break;
        case "QR-2":
          this.body = `La ubicación donde está escaneando el código QR no es válida`;
          break;
        case "QR-3":
          this.body = `${this.alert.description} no estás asignado en la zona para leer los códigos QR`;
          break;
        case "QR-4":
          this.body = `Error leyendo código QR, algo salio mal intentelo de nuevo`;
          break;
        case "QR-5":
          this.body = `Usuario tiene desactivado el permiso de acceso a la cámara`;
          break;
        case "QR-6":
          this.body = `No hay ningún dispositivo de cámara adecuado instalado`;
          break;
        case "QR-7":
          this.body = `La página no se sirve a través de HTTPS o localhost`;
          break;
        case "QR-8":
          this.body = `Tal vez la cámara ya está en uso`;
          break;
        case "QR-9":
          this.body = `Pediste la cámara frontal aunque no hay ninguna`;
          break;
        case "QR-10":
          this.body = `El navegador parece carecer de funciones`;
          break;
        // MORA
        case "MORA":
          this.body = "Servicio inactivo por mora en el pago.";
          break; 
        // Default
        default:
          this.body = this.alert.description;
          break;
      }
      return this.body;
    },
    validTypeAlert() {
      for (const key of Object.keys(this.list_alert)) {
        if (this.alert.type == key) {
          this.type_alert = this.list_alert[key][2];
          this.title = this.list_alert[key][1];
          this.icon = this.list_alert[key][0];
          this.color = key;
          break;
        }
      }
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
    exit() {
      if (event.keyCode === 27) {
        if (this.btn_cancel) {
          this.$emit("cancel");
        } else {
          this.$emit("exitEsc");
        }
      }
    },
    checkKey(event) {
      switch (event.which) {
        case 39: //right
          this.$refs.btn2.$el.focus();
          break;
        case 37: //left
          this.$refs.btn1.$el.focus();
          break;
        default:
          null;
          break;
      }
    },
  },
  created() {
    this.validTypeAlert();
  },
  mounted() {
    if (["p", "P"].includes(this.alert.option)) {
      this.msjalert(this.alert.code);
    } else {
      this.msjError(this.alert.code);
    }
    document.addEventListener("keydown", this.exit);
    document.addEventListener("keydown", this.checkKey);
  },
  destroyed() {
    document.removeEventListener("keydown", this.exit);
    document.removeEventListener("keydown", this.checkKey);
  },
};
</script>
